app.service('fck', function ($global, html) {
	var history = [];
	var fck_init_count = 0;
	var fck_oncomplete = [];

	function init($parent = $('body')) {
		var $fck = $('textarea.wysiwyg-hidden', $parent).not('.init, .template');
		fck_init_count = fck_init_count + $fck.length;
		checkFCK($fck);
		$fck.each(function () {
			run({
				name: $(this).attr('data-name'),
				classId: $(this).attr('data-class-id'),
				height: $(this).attr('data-height') ? $(this).attr('data-height') : 300,
				width: $(this).attr('data-width') ? $(this).attr('data-width') : '100%',
                toolbar: $(this).attr('data-toolbar') ? $(this).attr('data-toolbar') : 'EuroLite',
			});
			$(this).addClass('init');
		});
		
	}
	function run(params) {
		if (params && params.classId && params.name) {
			let hidden_textarea = document.getElementsByClassName(params.classId);
			if (hidden_textarea && hidden_textarea.length == 1) {
				let instanceFCK = new FCKeditor(params.name);
				instanceFCK.ForcePasteAsPlainText = true;
				instanceFCK.ClassId = params.classId;
				instanceFCK.BasePath = '/fck/fckeditor-v1/';
				instanceFCK.Value = hidden_textarea[0].value;
				instanceFCK.ToolbarSet = params.toolbar;
				instanceFCK.Height = params.height;
				instanceFCK.Width = params.width;
				instanceFCK.Create();
			}
		}
	}
	function update() {
		try {
			$('textarea.wysiwyg-hidden').each(function () {
				var instance = FCKeditorAPI.GetInstance($(this).attr('data-name'));
				instance.LinkedField.value = instance.GetHTML(true);
				instance.Events.FireEvent('OnAfterLinkedFieldUpdate');
			});
		} catch (error) {
		}
	}
    $global('fckInit', init);
	$global('fckUpdate', update);
	//$global('fckRun', run )


	function checkFCK($fck) {
		function close(){
			clearTimeout(timeout);
			html.remove('fck-loading');
		}
		var timeout;
		if (fck_init_count > 3) {
			html.add('fck-loading');
			console.log('liczba fck: '+fck_init_count);
			timeout = setTimeout(function(){
				console.log('awaryjne wyłączenie warstwy fck-loading');
				close();
			}, 10000);
		}
		window.FCKeditor_OnComplete = function (editorInstance) {

			fck_oncomplete.push(editorInstance.Name);
			console.log('fck załadowany: '+fck_oncomplete.length +' z '+ fck_init_count)

			if (fck_oncomplete.length == fck_init_count) {
				close();
			}

			var form = editorInstance.GetParentForm();
			editorInstance.Events.AttachEvent('OnAfterLinkedFieldUpdate', function (editorInstance) {
				let originalValue = form[editorInstance.Name].value;
				let value = '' + originalValue;
				function replace(){
					value = value.trim();
					if (value.endsWith('<br />')) {
						value = value.slice(0, -6);
						replace();
					} else if(value.endsWith('&nbsp;')){
						value = value.slice(0, -6);
						replace();
					}
				}
				replace();
				if(originalValue !== value){
					form[editorInstance.Name].value = value;
				}
			});
		}
	}
	return {
		init
	}
});
