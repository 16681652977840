app.service('filtersTableGroup',function(){

	function render($group) {
		var $addRowButton = $('.button-add', $group);
		var $removeRowButton = $('.button-delete', $group);
		var $mainFilters = $('.main-selection-filter', $group);
		
		function showFilterOptions(select) {
			var $row = $(select).closest('.filter-selection-row');
			var selectedMainFilterValue = $(select).val();
			var $filterOptionValues = $('.subfilter-options[data-filter-id="'+selectedMainFilterValue+'"]', $group);
			var type;
	
			$('.subselect-options-variant', $row).addClass('hide-element');
			$('.subselect-options-variant select', $row).val('');
	
			switch ($filterOptionValues.data('type')) {
				case 'DICTIONARY':
					type = "multiselect";
					break;
				case 'BOOLEAN':
					type = "dropdown";
					break;
			}
	
			$('.subselect-options-variant[data-select-type="'+type+'"] select', $row).html($filterOptionValues.html());
			$('.subselect-options-variant[data-select-type="'+type+'"]', $row).removeClass('hide-element');
	
		}
	
		function hideFiltersRow(button) {
			var $row = $(button).closest('.filter-selection-row');
			var $tableBody = $row.closest('tbody');
	
			//hide row
			$row.addClass('hide-element');
			
			// clear active main filter select
			$('.main-selection-filter', $row).val('');
	
			// clear and hide subselects options
			$('.subselect-options-variant', $row).addClass('hide-element');
			$('.subselect-options-variant select', $row).val('');
			$tableBody.append($row);
		}
	
		function showNextFilterRow() {
			var $firstHiddenSelectionFilterRow = $(".filter-selection-row[class~='hide-element']:first", $group);
	
			if ($firstHiddenSelectionFilterRow.length) {
				$firstHiddenSelectionFilterRow.removeClass('hide-element');
			} else {
				alert("Nie można dodać więcej filtrów opartych o cechy produktowe");
			}
		}

		$mainFilters.on('change', function() {
			showFilterOptions(this);
		})
		$addRowButton.on('click', function() {
			showNextFilterRow();
		})
		$removeRowButton.on('click', function() {
			hideFiltersRow(this);
		})

	}
	function init(){
		if($('.filters-table-group').length) {
			$('.filters-table-group').each(function() {
				render($(this));
			});
		}
	}
	return {
		init
	}
});
